import React from "react"
import * as stockListStyles from "./stockList.module.scss"

const StockList = ({ allBrands, universityBrands, centerBrands }) => {
  const uniqueUniversityBrands = Array.from(new Set(universityBrands)).sort()
  const uniqueCenterBrands = Array.from(new Set(centerBrands)).sort()

  return (
    <div className={stockListStyles.wrapper} id="stocklist" tabIndex={0}>
      <h2 className={stockListStyles.completeStocklists}>
        Complete Stocklists
      </h2>
      <div
        className={stockListStyles.listWrapper}
        role="group"
        aria-label="Stocklist Card"
      >
        <p
          className={stockListStyles.listText}
          aria-label="University City Stock"
        >
          <span className={stockListStyles.listLocation}>
            <h3>modern eye university city</h3> -
          </span>{" "}
          {uniqueUniversityBrands.join(", ")}
        </p>
        <p className={stockListStyles.listText} aria-label="Center City Stock">
          <span className={stockListStyles.listLocation}>
            <h3>modern eye center city</h3> -
          </span>{" "}
          {uniqueCenterBrands.join(", ")}
        </p>
      </div>
    </div>
  )
}

export default StockList
