import React from "react"
import Tapestry from "../components/frames/tapestry"
import FramesGrid from "../components/frames/framesGrid"
import * as framesStyles from "./frames.module.scss"
import StockList from "../components/frames/stockList"
import Seo from "../components/seo"

const Frames = () => {
  return (
    <div className={framesStyles.wrapper}>
      <Seo
        title="Frames"
        description="We offer the finest eyewear with an emphasis on handcrafted frames by independent designers. Our staff is dedicated to helping you find just the right frame for fit, comfort, and, style; classic, modern, professional, or space-aged. We have implemented comprehensive safeguards to keep your experience safe, relaxed, and fun!"
      />
      <Tapestry />
      <FramesGrid />
    </div>
  )
}

export default Frames
