import React, { useContext } from "react"
import { StaticImage } from "gatsby-plugin-image"
import * as framesGridStyles from "./framesGrid.module.scss"
import { graphql, useStaticQuery } from "gatsby"
import { IoMdArrowDropdown } from "react-icons/io"
import { GlobalStateContext } from "../../Context/GlobalContextProvider"
import scrollTo from "gatsby-plugin-smoothscroll"
import FrameCard from "./frameCard"
import StockList from "./stockList"

const FramesGrid = () => {
  const context = useContext(GlobalStateContext)

  const data = useStaticQuery(graphql`
    {
      frames: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/content/frames/" } }
        sort: { fields: [frontmatter___title], order: ASC }
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              location
              image1 {
                childImageSharp {
                  gatsbyImageData
                  id
                }
              }
              image1Description
              website
              logo {
                childImageSharp {
                  gatsbyImageData
                  id
                }
              }
            }
            fields {
              slug
            }
            excerpt(pruneLength: 122)
          }
        }
      }
    }
  `)

  const { edges } = data.frames

  const allBrands = edges.map(edge => edge.node.frontmatter.title)

  const uniCityBrands = edges
    .filter(
      edge =>
        edge.node.frontmatter.location.includes("University") ||
        edge.node.frontmatter.location.includes("Both")
    )
    .map(edge => edge.node.frontmatter.title)

  const centerCityBrands = edges
    .filter(
      edge =>
        edge.node.frontmatter.location.includes("Center") ||
        edge.node.frontmatter.location.includes("Both")
    )
    .map(edge => edge.node.frontmatter.title)

  return (
    <GlobalStateContext.Consumer>
      {context => (
        <div>
          <div>
            <div className={framesGridStyles.customSelect}>
              <StaticImage
                className={framesGridStyles.mobileEyeballLeft}
                src="../../images/frames/eyeball-left.png"
                placeholder="blurred"
                alt=""
                imgStyle={{ objectFit: `contain` }}
              />
              <div className={framesGridStyles.customSelectWrapper}>
                <select
                  id="frames-filter-mobile"
                  value={
                    context.showUniversity
                      ? "uCity"
                      : context.showCenter
                      ? "cCity"
                      : "allCollections"
                  }
                  className={framesGridStyles.selectButton}
                  onChange={event => {
                    const selectedFramesList = event.target.value
                    if (selectedFramesList === "uCity") {
                      context.showUniversityClick()
                      event.target.value = "uCity"
                    } else if (selectedFramesList === "cCity") {
                      context.showCenterClick()
                      event.target.value = "cCity"
                    } else if (selectedFramesList === "allCollections") {
                      context.showAllClick()
                      event.target.value = "allCollections"
                    }
                  }}
                  aria-label="Select a collection"
                >
                  <option value="allCollections">all collections</option>
                  <option value="uCity">University City</option>
                  <option value="cCity">Center City</option>
                </select>
                <span aria-hidden="true">
                  <IoMdArrowDropdown className={framesGridStyles.customArrow} />
                </span>
              </div>

              <StaticImage
                className={framesGridStyles.mobileEyeballRight}
                src="../../images/frames/eyeball-right.png"
                placeholder="blurred"
                alt=""
                imgStyle={{ objectFit: `contain` }}
              />
            </div>
            <button
              onClick={() => scrollTo("#stocklist")}
              className={framesGridStyles.mobileStocklistButton}
            >
              Tap <span className={framesGridStyles.listButtonHere}>here</span>{" "}
              for complete stocklist
            </button>
          </div>
          {/* desktop filtering */}
          <div className={framesGridStyles.buttonsGrid}>
            <StaticImage
              className={framesGridStyles.eyeballLeft}
              src="../../images/frames/eyeball-left.png"
              placeholder="blurred"
              alt=""
              imgStyle={{ objectFit: `contain` }}
            />
            <div
              className={framesGridStyles.collectionButtonsWrapper}
              role="group"
            >
              <button
                id="allButton"
                onClick={() => context.showAllClick()}
                className={
                  context.showAll
                    ? `${framesGridStyles.buttonActive} ${framesGridStyles.allCollectionsButton} ${framesGridStyles.collectionButtons}`
                    : `${framesGridStyles.allCollectionsButton} ${framesGridStyles.collectionButtons}`
                }
                aria-label="Show all collections"
              >
                all collections
              </button>
              <button
                id="universityButton"
                onClick={() => context.showUniversityClick()}
                className={
                  context.showUniversity
                    ? `${framesGridStyles.buttonActive}  ${framesGridStyles.collectionButtons}`
                    : ` ${framesGridStyles.collectionButtons}`
                }
                aria-label="Show University City collection"
              >
                University City
              </button>
              <button
                id="centerButton"
                onClick={() => context.showCenterClick()}
                className={
                  context.showCenter
                    ? `${framesGridStyles.buttonActive} ${framesGridStyles.centerCityCollectionsButton} ${framesGridStyles.collectionButtons}`
                    : `${framesGridStyles.centerCityCollectionsButton} ${framesGridStyles.collectionButtons}`
                }
                aria-label="Show Center City collection"
              >
                Center City
              </button>
            </div>
            <button
              onClick={() => {
                document
                  .getElementById("stocklist")
                  .scrollIntoView({ behavior: "smooth" })
                document.getElementById("stocklist").focus()
              }}
              href="#stocklist"
              id="listCollectionButton"
              className={framesGridStyles.listCollectionButton}
              aria-label="Scroll to stocklist"
            >
              or click{" "}
              <span className={framesGridStyles.listButtonHere}>here</span> for
              complete stock in list form
            </button>
            <StaticImage
              className={framesGridStyles.eyeballRight}
              src="../../images/frames/eyeball-right.png"
              placeholder="blurred"
              alt=""
              imgStyle={{ objectFit: `contain` }}
            />
          </div>
          {/* frames grid */}
          <div>
            {context.showAll ? (
              <ul className={framesGridStyles.allCardsWrapper} role="group">
                {edges.map(edge => (
                  <FrameCard
                    id={edge.node.id}
                    slug={edge.node.fields.slug}
                    logo={
                      edge.node.frontmatter.logo.childImageSharp.gatsbyImageData
                    }
                    title={edge.node.frontmatter.title}
                    location={edge.node.frontmatter.location}
                    image={
                      edge.node.frontmatter.image1.childImageSharp
                        .gatsbyImageData
                    }
                    imageDescription={edge.node.frontmatter.image1Description}
                    website={edge.node.frontmatter.website}
                    excerpt={edge.node.excerpt}
                  />
                ))}
              </ul>
            ) : null}

            {context.showCenter ? (
              <ul className={framesGridStyles.allCardsWrapper} role="group">
                {edges
                  .filter(
                    edge =>
                      edge.node.frontmatter.location.includes("Center") ||
                      edge.node.frontmatter.location.includes("Both")
                  )
                  .map(edge => (
                    <FrameCard
                      id={edge.node.id}
                      slug={edge.node.fields.slug}
                      logo={
                        edge.node.frontmatter.logo.childImageSharp
                          .gatsbyImageData
                      }
                      title={edge.node.frontmatter.title}
                      location={edge.node.frontmatter.location}
                      image={
                        edge.node.frontmatter.image1.childImageSharp
                          .gatsbyImageData
                      }
                      imageDescription={edge.node.frontmatter.image1Description}
                      website={edge.node.frontmatter.website}
                      excerpt={edge.node.excerpt}
                    />
                  ))}
              </ul>
            ) : null}

            {context.showUniversity ? (
              <ul className={framesGridStyles.allCardsWrapper} role="group">
                {edges
                  .filter(
                    edge =>
                      edge.node.frontmatter.location.includes("University") ||
                      edge.node.frontmatter.location.includes("Both")
                  )
                  .map(edge => (
                    <FrameCard
                      id={edge.node.id}
                      slug={edge.node.fields.slug}
                      logo={
                        edge.node.frontmatter.logo.childImageSharp
                          .gatsbyImageData
                      }
                      title={edge.node.frontmatter.title}
                      location={edge.node.frontmatter.location}
                      image={
                        edge.node.frontmatter.image1.childImageSharp
                          .gatsbyImageData
                      }
                      imageDescription={edge.node.frontmatter.image1Description}
                      website={edge.node.frontmatter.website}
                      excerpt={edge.node.excerpt}
                    />
                  ))}
              </ul>
            ) : null}

            <StockList
              allBrands={allBrands}
              universityBrands={uniCityBrands}
              centerBrands={centerCityBrands}
            />
          </div>
        </div>
      )}
    </GlobalStateContext.Consumer>
  )
}

export default FramesGrid
